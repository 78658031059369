<!-- Finder particular de Hornos -->

<template>
  <div class="hornos_F">
    <!-- Base Finder -->
    <base_F
      v-if="schema"
      :schema="schema"
      :header="header"
      :titulo="titulo"
      :tipo="tipo"
      :sGrid="sGrid"
      :Entorno="Entorno"
      :btn_cerrarForm="btn_cerrarForm"
      :dblclick="dblclick"
      :selectable="selectable"
      @onEvent="event_capture"
    >
      <template v-slot:filtro="{ SEntorno }">
        <v-row no-gutters>
          <v-col md="6">
            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.nombre.value"
              :label="schema.ctrls.nombre.label"
              @keypress.13="acciones_filtro('buscarHorno')"
            >
            </v-text-field>
          </v-col>

          <v-col md="1">
            <v-btn
              v-bind="SEntorno.btn"
              @click="acciones_filtro('buscarHorno')"
            >
              <v-icon dark>{{ SEntorno.btn.icono }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col md="6">
            <ctrlfinder :schema="schema.ctrls.atlas"> </ctrlfinder>
          </v-col>
        </v-row>
      </template>
    </base_F>
  </div>
</template>

<script>
import { mixinFinder } from "@/mixins/mixinFinder.js";
import plugs from "@/common/general_plugs";
const base_F = () => plugs.groute("base_F.vue", "base");
const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");

export default {
  mixins: [mixinFinder],
  components: { base_F, ctrlfinder },
  props: {
    tipo: { type: String, default: "finder" },
    header: { type: Boolean, default: true },
    btra: { type: [Array, Object], default: undefined },
    btn_cerrarForm: { type: Boolean, default: false },
    dblclick: { type: String, default: "0" },
    selectable: { type: Boolean, default: true }
  },

  data() {
    return {
      // variables de configuración
      Entorno: undefined,

      // variables del componente
      finder: "hornoS", // api particular
      titulo: "Hornos", // título a mostrar en la cabecera del Finder
      schema: null, // schema

      sGrid: {
        headers: [],
        records: [],
        header_ini: [
          { text: "Hornos", value: "name", width: "40%" },

          { text: "Actualizado", value: "modificado", width: "10%" },

          { text: "Atlas", value: "atlas", width: "40%" },

          {
            text: "Acciones",
            value: "acciones",
            sortable: false,
            width: "10%",
            align: "right"
          }
        ]
      }
    };
  },

  methods: {
    event_capture(evt) {
      console.log("*** event_capture hornos_F. Accion: " + evt.accion + " ***");

      if (this.$isFunction(this[evt.accion])) this[evt.accion](evt);
      this.$emit("onEvent", evt);
    }
  }
};
</script>
